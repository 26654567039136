window.App ||= {}

document.addEventListener 'turbolinks:load', ->
  $(document).foundation()
  App.init()

document.addEventListener 'turbolinks:render', ->
  App.trial_form()
  _takashi_ga('takashi.send', 'pageview')

document.addEventListener 'turbolinks:before-cache', ->
  # $('#offCanvasRight').foundation('close')
