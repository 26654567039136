import { config, library, dom } from '@fortawesome/fontawesome-svg-core'

import {
  faBars,
  faCaretRight, faCaretDown,
  faChevronLeft, faChevronRight,
  faAngleLeft,
  faPhone, faEnvelope,
  faRss, faBolt,
  faExclamationTriangle,
  faSignInAlt,
  faQuestionCircle,
  faCalendar,
  faMapPin, faTags, faVideo,
  faList, faExternalLinkAlt, faCheckCircle,
  faWheelchair, faShower, faCreditCard, faMedkit, faWifi,
  faLocationArrow, faGlobeEurope, faDownload, faMapMarker, faMoneyBill,
} from '@fortawesome/free-solid-svg-icons'
import {
  faSquare, faCalendarTimes
} from '@fortawesome/free-regular-svg-icons'
import {
  faFacebookF, faInstagram, faYoutube,
} from '@fortawesome/free-brands-svg-icons'

library.add(
  // Solids
  faBars,
  faCaretRight, faCaretDown,
  faChevronLeft, faChevronRight,
  faAngleLeft,
  faPhone, faEnvelope,
  faRss, faBolt,
  faExclamationTriangle,
  faSignInAlt,
  faQuestionCircle,
  faCalendar, faCalendarTimes,
  faMapPin, faTags, faVideo,
  faList, faExternalLinkAlt, faCheckCircle,
  faSquare, faWheelchair, faShower, faCreditCard, faMedkit, faWifi,
  faLocationArrow, faGlobeEurope, faDownload, faMapMarker, faMoneyBill,

  // Brands
  faFacebookF, faInstagram, faYoutube,
)

config.mutateApproach = 'sync'
dom.watch()
