window.App ||= {}

App.maps = ->

  center = null
  pan_width = -($('.section--map').width() / 100) * 6

  setup_maps = () ->
    locations = $('#map').data('coordinates')

    map = new google.maps.Map(document.getElementById('map'), {
      zoom: 5,
      disableDefaultUI: true,
      scrollwheel: false,
      zoomControl: false,
      disableDoubleClickZoom: true,
      panControl: false,
      streetViewControl: false,
      draggable: false
    })

    bounds = new google.maps.LatLngBounds()

    for location in  locations
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(location.lat, location.lng),
        map: map,
        url: location.url
      })

      # extend the bounds to include each marker's position
      bounds.extend(marker.position)

      # add event listener that links marker to location page
      google.maps.event.addListener marker, 'click', ->
        window.location.href = this.url

    # now fit the map to the newly inclusive bounds
    map.fitBounds(bounds)

    # # https://stackoverflow.com/questions/10656743/how-to-offset-the-center-point-in-google-maps-api-v3
    # # TODO:
    # # - Calculate pixel amount from 50% to 75% on current screen size
    # # - Add events listener that centers map to 75% on after a resize
    if (Foundation.MediaQuery.atLeast('large'))
      map.panBy(pan_width, 0)

    google.maps.event.addDomListener map, 'idle', ->
      center = map.getCenter()

    google.maps.event.addDomListener window, 'resize', ->
      map.setCenter(center)

    # $(window).on 'changed.zf.mediaquery', (event, newSize, oldSize) ->
    #   if newSize == 'small' || newSize == 'medium'
    #     map.fitBounds(bounds)
    #   else
    #     map.fitBounds(bounds)
    #     map.panBy(pan_width, 0)

  init_google_maps = () ->
    if typeof google != "undefined"
      setup_maps()
    else
      console.log 'waiting'
      setTimeout(init_google_maps, 500)

  if $('#map').length > 0
    init_google_maps()
