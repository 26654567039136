import { Controller } from "stimulus"

import Velocity from 'velocity-animate'

export default class extends Controller {
  static targets = ['expandable', 'collapsible']

  connect() {
    if (this.data.get('expanded') == null) this.data.set('expanded', false)
  }

  expand() {
    event.preventDefault()
    this.data.set('expanded', true)

    if (this.data.get('animation') == 'slide') {
      Velocity(this.expandableTarget, 'slideDown')
    }
    this.expandableTarget.querySelectorAll('select, input, textarea').forEach(function(item, i){ item.disabled = false; });

    if (this.hasCollapsibleTarget) {
      if (this.data.get('animation') == 'slide') {
        Velocity(this.collapsibleTarget, 'slideUp')
      }
      this.collapsibleTarget.querySelectorAll('select, input, textarea').forEach(function(item, i){ item.disabled = true; });
    }
  }

  collapse() {
    event.preventDefault()
    this.data.set('expanded', false)

    if (this.data.get('animation') == 'slide') {
      Velocity(this.expandableTarget, 'slideUp')
    }
    this.expandableTarget.querySelectorAll('select, input, textarea').forEach(function(item, i){ item.disabled = true; });

    if (this.hasCollapsibleTarget) {
      if (this.data.get('animation') == 'slide') {
        Velocity(this.collapsibleTarget, 'slideDown')
      }
      this.collapsibleTarget.querySelectorAll('select, input, textarea').forEach(function(item, i){ item.disabled = false; });
    }
  }

  toggle() {
    event.preventDefault()

    if (this.data.get('expanded') == 'true') {
      this.collapse()
    } else {
      this.expand()
    }
  }
}
