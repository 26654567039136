import { Controller } from "stimulus"

import * as intlTelInput from 'intl-tel-input'

export default class extends Controller {
  static targets = ['input', 'hidden']

  connect() {
    var controller = this

    this.iti = intlTelInput(this.inputTarget, {
      utilsScript: appConfig.iti.utilsScriptPath,
      preferredCountries: ['ch', 'de', 'at'],
      initialCountry: appConfig.iti.defaultCountryCode
    })

    var event = new Event('change');
    this.inputTarget.addEventListener("countrychange", function() {
      controller.inputTarget.dispatchEvent(event)
    })
  }

  disconnect() {
    this.iti.destroy()
  }

  setNumber() {
    var number = this.iti.getNumber()
    this.hiddenTarget.value = number
  }
}
