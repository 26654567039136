import { Controller } from "stimulus"

import moment from 'moment'

export default class extends Controller {
  connect () {
    this.element.setAttribute('novalidate', true)
    this.element.addEventListener('change', this.onChange, true)
    this.element.addEventListener('submit', this.onSubmit)
    this.element.addEventListener('ajax:beforeSend', this.onSubmit)
  }

  disconnect () {
    this.element.removeEventListener('change', this.onChange)
    this.element.removeEventListener('submit', this.onSubmit)
    this.element.removeEventListener('ajax:beforeSend', this.onSubmit)
  }

  onChange = (event) => {
    this.validateField(event.target)
  }

  onSubmit = (event) => {
    if (event.target === this.element) {
      let submit = this.element.querySelector('input[type="submit"]')
      if (!this.validateForm()) {
        event.preventDefault()
        event.stopImmediatePropagation() // stops actions in other controllers after this one if form is invalid
        this.firstInvalidField.focus()
        setTimeout(function() {
          submit.disabled = false
        }, 20)
        return false
      }
    }
  }

  validateForm () {
    let isValid = true
    // Not using `find` because we want to validate all the fields
    this.formFields.forEach((field) => {
      if (this.shouldValidateField(field) && !this.validateField(field)) isValid = false
    })
    return isValid
  }

  validateField (field) {
    if (!this.shouldValidateField(field))
      return true

    // Reset custom errors
    field.setCustomValidity('')

    // Custom validations
    if (field.dataset.validator == 'checkbox_limit') {
      this.validateCheckboxLimit(field)
    }

    if (field.value.trim().length > 0 || field.required) {
      switch (field.dataset.validator) {
        case 'phone':
          this.validatePhone(field)
          break
        case 'equals_or_less_than':
          this.validateEqualsOrLessThan(field)
          break
        case 'equals_or_greater_than':
          this.validateEqualsOrGreaterThan(field)
          break
        case 'greater_than':
          this.validateGreaterThan(field)
          break
      }
    }

    // Check validity
    const isValid = field.checkValidity()

    // Display error
    this.toggleLabelClass(field, isValid)
    this.toggleFieldClass(field, isValid)
    this.refreshErrorForInvalidField(field, isValid)

    return isValid
  }

  shouldValidateField (field) {
    return !field.disabled && ![undefined, 'hidden', 'file', 'reset', 'submit', 'button'].includes(field.type)
  }

  toggleLabelClass(field, isValid) {
    let fieldContainer = field.closest('.field')
    if(!fieldContainer)
      return
    let label = fieldContainer.querySelector('label')
    if(label) {
      label.classList.toggle('is-invalid-label', !isValid)
    }
  }

  toggleFieldClass(field, isValid) {
    field.classList.toggle('is-invalid-input', !isValid)
  }

  refreshErrorForInvalidField (field, isValid) {
    const fieldContainer = field.closest('.field')
    if(!fieldContainer)
      return;

    this.removeExistingErrorMessage(fieldContainer)
    if (!isValid && field.dataset.errorMessage != 'false')
      this.showErrorForInvalidField(field, fieldContainer)
  }

  removeExistingErrorMessage (fieldContainer) {
    const existingErrorMessageElement = fieldContainer.querySelector('.form-error')
    if (existingErrorMessageElement)
      existingErrorMessageElement.parentNode.removeChild(existingErrorMessageElement)
  }

  showErrorForInvalidField (field, fieldContainer) {
    const errorWrapper = fieldContainer.querySelector('.form-error-wrapper') || fieldContainer
    errorWrapper.insertAdjacentHTML('beforeend', this.buildFieldErrorHtml(field))
  }

  buildFieldErrorMessage(field) {
    return field.dataset.errorMessage || field.validationMessage
  }

  buildFieldErrorHtml (field) {
    let message = this.buildFieldErrorMessage(field)
    return `<div class="hint form-error is-visible">${message}</div>`
  }

  get formFields () {
    return Array.from(this.element.elements)
  }

  get firstInvalidField () {
    return this.formFields.find(field => !field.checkValidity())
  }

  validateCheckboxLimit(field) {
    let fieldContainer = field.closest('.field')
    let group = fieldContainer.querySelector('.checkbox-button-group')

    let min = fieldContainer.dataset.validatorMin
    let message = fieldContainer.dataset.errorMessage || `At least ${min} checkbox must be selected.`
    let checked = fieldContainer.querySelectorAll('input:checked').length

    const isValid = (checked >= min)

    group.classList.toggle('is-invalid-input', !isValid)
    field.setCustomValidity(isValid ? "" : message)
  }

  validateGreaterThan(field) {
    let message = 'Must be greater than min value.'

    let type = field.dataset.greaterThanType
    let greater_than_id = field.dataset.greaterThan

    let from = document.getElementById(greater_than_id).value
    let to   = field.value

    if ((from.length > 0) && (to.length > 0)) {
      let isValid
      let format

      switch (type) {
        case 'date':
          format = field.dataset.datepickerFormat || 'YYYY-MM-DD'
          isValid = moment(from, format) < moment(to, format)
          break
        case 'time':
          format = 'hh:mm'
          isValid = moment(from, format) < moment(to, format)
          break
        default:
          isValid = parseInt(from) < parseInt(to)
          break
      }

      field.setCustomValidity(isValid ? "" : message)
    }
  }

  validateEqualsOrGreaterThan(field) {
    let message = 'Must be equal or greater than min value.'

    let type = field.dataset.equalsOrGreaterThanType
    let greater_than_id = field.dataset.equalsOrGreaterThan

    let from = document.getElementById(greater_than_id).value
    let to   = field.value

    if ((from.length > 0) && (to.length > 0)) {
      let isValid
      let format

      switch (type) {
        case 'date':
          format = field.dataset.datepickerFormat || 'YYYY-MM-DD'
          isValid = moment(from, format) <= moment(to, format)
          break
        default:
          isValid = parseInt(from) <= parseInt(to)
          break
      }

      field.setCustomValidity(isValid ? "" : message)
    }
  }

  validateEqualsOrLessThan(field) {
    let message = 'Must be equal or less than min value.'

    let type = field.dataset.equalsOrLessThanType
    let less_than_id = field.dataset.equalsOrLessThan

    let from = document.getElementById(less_than_id).value
    let to   = field.value

    if ((from.length > 0) && (to.length > 0)) {
      let isValid
      let format

      switch (type) {
        case 'date':
          format = field.dataset.datepickerFormat || 'YYYY-MM-DD'
          isValid = moment(from, format) >= moment(to, format)
          break
        default:
          isValid = parseInt(from) >= parseInt(to)
          break
      }

      field.setCustomValidity(isValid ? "" : message)
    }
  }

  validatePhone(field) {
    let message = 'Phone number is invalid.'

    let phoneController = this.application.getControllerForElementAndIdentifier(
      field.closest("[data-controller='phone']"),
      "phone"
    )
    const isValid = phoneController.iti.isValidNumber()

    field.setCustomValidity(isValid ? "" : message)
  }
}
