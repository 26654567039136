/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Stylesheets
require('stylesheets/websites/default/application.sass')

// Images
// require.context('../images', true)

global.$ = require("jquery")

// Require defaults
require("@rails/ujs").start()
require("turbolinks").start()

// Font Awesome
import "src/websites/default/fontawesome"

// Foundation
import { Foundation } from '@websites/foundation-sites-v6.4.3/js/foundation.core'
import { Equalizer } from '@websites/foundation-sites-v6.4.3/js/foundation.equalizer'
import { OffCanvas } from '@websites/foundation-sites-v6.4.3/js/foundation.offcanvas'
import { Accordion } from '@websites/foundation-sites-v6.4.3/js/foundation.accordion'

Foundation.addToJquery($)


Foundation.plugin(Equalizer, 'Equalizer')
Foundation.plugin(OffCanvas, 'OffCanvas')
Foundation.plugin(Accordion, 'Accordion')

// LEGACY JS START

// Vendor Plugins
import 'websites/autosize-v1.17.8.min.js'

// Modules
import 'src/websites/default/modules/helpers.coffee'
import 'src/websites/default/modules/trial_form.coffee'
import 'src/websites/default/modules/fixed_sidebar.coffee'
import 'src/websites/default/modules/autosize.coffee'
import 'src/websites/default/modules/maps.coffee'

// Init
import 'src/websites/default/init/application.coffee'
import 'src/websites/default/init/turbolinks.coffee'

// LEGACY JS END

// Stimulus
import "controllers/websites/default"
