import { Controller } from "stimulus"

import flatpickr from 'flatpickr'
import { German } from "flatpickr/dist/l10n/de.js"
import moment from 'moment'

export default class extends Controller {
  static targets = []

  connect() {
    let format = this.data.get('format')
    let minDate = this.data.get('min') != undefined ? moment(this.data.get('min')).format(format) : null
    let controller = this
    let id = this.data.get('id')
    let type = this.data.get('type')
    let contraType = type == 'start' ? 'end' : 'start'
    let contraPicker = document.querySelector(`[data-datepicker-id='${id}'][data-datepicker-type='${contraType}']`)

    this.flatpickr = flatpickr(this.element, {
      locale: German,
      dateFormat: format,
      minDate: minDate,
      allowInput: true,
      parseDate: (datestr, format) => {
        return moment(datestr, format, true).toDate();
      },
      formatDate: (date, format, locale) => {
        // locale can also be used
        return moment(date).format(format);
      },
      onOpen: function(selectedDates, dateStr, instance) {
        instance.input.readOnly = true
      },
      onClose: function(selectedDates, dateStr, instance) {
        instance.input.readOnly = false
        instance.input.blur()
      },
      onChange: function(selectedDates, dateStr, instance) {
        if (type != undefined) {
          contraPicker._flatpickr.redraw()
        }
      },
      disable: [
        function(date) {
          if (type == 'start') {
            let endDate = moment(contraPicker.value, format, true).toDate()
            return date > endDate
          } else if (type == 'end') {
            let startDate = moment(contraPicker.value, format, true).toDate()
            return date < startDate
          }
        }
      ]
    })
  }

  disconnect() {
    this.flatpickr.destroy()
    if (this.element.dataset.target != undefined && this.element.dataset.target.includes('schedule.date')) {
      this.element.readOnly = true
    }
  }
}
