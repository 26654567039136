window.App ||= {}

App.fixed_sidebar = ->

  $sidebar_content = $('.sidebar-content')
  $sidebar_wrapper = $('.sidebar-wrapper')
  $sidebar = $('.sidebar')
  $sidebar_section = $('.sidebar').closest('.section')
  margin_top = 0
  top = 0

  set_sidebar_dimensions = ->
    margin_top = parseFloat($sidebar_section.css('padding-top'))

    $sidebar.width($sidebar_wrapper.width())
    $sidebar.css({'margin-top': "-#{margin_top*2}px"})
    top = $sidebar.offset().top - margin_top

  if $sidebar.length > 0

    set_sidebar_dimensions()

    $(window).on 'resize', ->
      set_sidebar_dimensions()

    $(window).scroll (event) ->

      bottom = $sidebar_content.offset().top + $sidebar_content.outerHeight() - $sidebar.height() - (margin_top * 2)

      y = $(this).scrollTop()

      if (y >= bottom)
        $sidebar.addClass('sidebar--bottom')
        $sidebar.removeClass('sidebar--fixed')
        $sidebar.css({'position': 'absolute', 'top': bottom - $sidebar_content.offset().top + margin_top*2})
      else if (y >= top)
        $sidebar.addClass('sidebar--fixed')
        $sidebar.removeClass('sidebar--bottom')
        $sidebar.css({'position': '', 'top': margin_top*3})
      else
        $sidebar.removeClass('sidebar--fixed')
        $sidebar.removeClass('sidebar--bottom')
        $sidebar.css({'position': '', 'top': ''})
